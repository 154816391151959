<template>
  <SectionY :padding="data.padding?.value">
    <ContainerX>
      <h2
        v-if="data.heading"
        class="default-component-title mx-auto mb-14 xl:mb-20"
      >
        <span v-html="$replaceHtmlTags(data.heading)" />
      </h2>

      <!-- ONE TESTIMONIAL -->
      <InViewAnimate>
        <Testimonial
          v-if="testimonials.length === 1"
          :testimonial="testimonials[0]"
          class="mx-auto"
          :has-heading="!!data.heading"
        />
      </InViewAnimate>
    </ContainerX>
    <!-- SLIDER -->
    <template v-if="testimonials.length > 1">
      <InViewAnimate>
        <Swiper
          :slides-per-view="1"
          class="relative"
          :loop="true"
          @swiper="onSwiper"
        >
          <SwiperSlide
            v-for="testimonial in testimonials"
            :key="testimonial.id"
            class=""
          >
            <div class="flex w-full justify-center px-container">
              <Testimonial
                :testimonial="testimonial"
                :has-heading="!!data.heading"
                class="relative my-1"
              />
            </div>
          </SwiperSlide>
          <div
            class="inset-x-0 top-1/2 z-10 mt-9 xl:absolute xl:-translate-y-1/2"
          >
            <SwiperButtons
              ref="swiperButtons"
              class="mx-auto max-w-[6.75rem] xl:max-w-[69rem]"
              :reach-beginning="false"
              @prev="swiper.slidePrev()"
              @next="swiper.slideNext()"
            />
          </div>
        </Swiper>
      </InViewAnimate>
    </template>
  </SectionY>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const allTestimonials = ref([])

// SWIPER
let swiper = {}

const onSwiper = (swiperElement) => {
  swiper = swiperElement
}

// INITIAL LOAD
if (props.data.testimonial_selection.value !== 'custom') {
  // Get all testimonials

  const { data } = await useAsyncGql({
    operation: 'Testimonials',
  })

  allTestimonials.value = data?.value?.entriesRandom?.data || []

  if (!allTestimonials.value) {
    console.error('Could not fetch testimonials in Testimonials.vue')
  }
}

const testimonials = computed(() => {
  if (props.data.testimonial_selection.value === 'custom') {
    return props.data.testimonials
  } else {
    return allTestimonials.value
  }
})
</script>
